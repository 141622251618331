<template>
    <div>
        <v-combobox
            v-model="newValue"
            :items="options"
            :error="errors && errors.length > 0"
            outlined
            :class="errors && errors.length ? 'hasError' : ''"
            item-text="text"
            :clearable="clearable || false"
            @change="emitValue"
            :disabled="disabled || false"
            :label="required ? label + '*' : label" 
            multiple
            chips
            deletable-chips
            :hide-no-data="!options.length"
            hint="Press Enter to commit each entry."
        >
            <template v-slot:append>
                <v-icon class="error-icon" v-if="errors && errors.length">mdi-alert</v-icon>            
                <v-icon v-else-if="!options.length"></v-icon>
            </template>
        </v-combobox>

        <ErrorMessages :errors="errors"></ErrorMessages>
    </div>
    
</template>

<script>
export default {
    name: 'Combobox',
    data() {
        return {
            newValue: ""
        }
    },
    props: {
        value: {
            type: [String, Array],
            default: null
        },
        type: String,
        rules: String,
        label: String,
        required: Boolean,
        disabled: Boolean,
        clearable: Boolean,
        options: {
            type: Array,
            default: () => []
        },
        errors: {
            type: Array,
            default: () => []
        }
    },
    emits: [
        'change'
    ],
    methods: {
        emitValue() {
            this.$emit('change', this.newValue)       
        },
    },
    computed: {
        optionsDisplay() {
            if (!this.options.length) return [];
            else {
                if (this.options[0].value) return this.options;
                else {
                    return this.options.map(o => {
                        return {value: o, text: o}
                    });
                }
            }
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                this.newValue = newVal;
            }
        },
    },
}
</script>

<style scoped>
    ::v-deep .v-text-field__details {padding: 5px 0!important;}
    ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box .v-select__selections, 
    ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections {
        min-height: auto;
    }
</style>
